import _global3 from "../internals/global";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _redefineAll from "../internals/redefine-all";
import _internalMetadata from "../internals/internal-metadata";
import _collection from "../internals/collection";
import _collectionWeak from "../internals/collection-weak";
import _isObject from "../internals/is-object";
import _objectIsExtensible from "../internals/object-is-extensible";
import _internalState from "../internals/internal-state";
import _nativeWeakMap from "../internals/native-weak-map";
var _global2 = _global3;
var uncurryThis = _functionUncurryThis;
var redefineAll = _redefineAll;
var InternalMetadataModule = _internalMetadata;
var collection = _collection;
var collectionWeak = _collectionWeak;
var isObject = _isObject;
var isExtensible = _objectIsExtensible;
var enforceInternalState = _internalState.enforce;
var NATIVE_WEAK_MAP = _nativeWeakMap;
var IS_IE11 = !_global2.ActiveXObject && "ActiveXObject" in _global2;
var InternalWeakMap;

var wrapper = function (init) {
  return function WeakMap() {
    return init(this, arguments.length ? arguments[0] : undefined);
  };
}; // `WeakMap` constructor
// https://tc39.es/ecma262/#sec-weakmap-constructor


var $WeakMap = collection("WeakMap", wrapper, collectionWeak); // IE11 WeakMap frozen keys fix
// We can't use feature detection because it crash some old IE builds
// https://github.com/zloirock/core-js/issues/485

if (NATIVE_WEAK_MAP && IS_IE11) {
  InternalWeakMap = collectionWeak.getConstructor(wrapper, "WeakMap", true);
  InternalMetadataModule.enable();
  var WeakMapPrototype = $WeakMap.prototype;
  var nativeDelete = uncurryThis(WeakMapPrototype["delete"]);
  var nativeHas = uncurryThis(WeakMapPrototype.has);
  var nativeGet = uncurryThis(WeakMapPrototype.get);
  var nativeSet = uncurryThis(WeakMapPrototype.set);
  redefineAll(WeakMapPrototype, {
    "delete": function (key) {
      if (isObject(key) && !isExtensible(key)) {
        var state = enforceInternalState(this);
        if (!state.frozen) state.frozen = new InternalWeakMap();
        return nativeDelete(this, key) || state.frozen["delete"](key);
      }

      return nativeDelete(this, key);
    },
    has: function has(key) {
      if (isObject(key) && !isExtensible(key)) {
        var state = enforceInternalState(this);
        if (!state.frozen) state.frozen = new InternalWeakMap();
        return nativeHas(this, key) || state.frozen.has(key);
      }

      return nativeHas(this, key);
    },
    get: function get(key) {
      if (isObject(key) && !isExtensible(key)) {
        var state = enforceInternalState(this);
        if (!state.frozen) state.frozen = new InternalWeakMap();
        return nativeHas(this, key) ? nativeGet(this, key) : state.frozen.get(key);
      }

      return nativeGet(this, key);
    },
    set: function set(key, value) {
      if (isObject(key) && !isExtensible(key)) {
        var state = enforceInternalState(this);
        if (!state.frozen) state.frozen = new InternalWeakMap();
        nativeHas(this, key) ? nativeSet(this, key, value) : state.frozen.set(key, value);
      } else nativeSet(this, key, value);

      return this;
    }
  });
}

export default {};